import { PlatformConfig } from '@blockworks/platform';

const getResearchUrl = () => {
    if (process.env.VERCEL_ENV === 'preview') {
        return 'https://app.staging.blockworksresearch.com' as const;
    }
    if (process.env.VERCEL_ENV === 'production') {
        return 'https://app.blockworksresearch.com' as const;
    }
    return 'https://localhost:3000' as const;
};

const StudioConfig = {
    ...PlatformConfig,
    title: 'Blockworks Studio',
    description:
        'Blockworks Studio is the most powerful data graphing platform in crypto.' +
        'It brings research, data, tokenomics, governance, models and more into one ' +
        'place so you can make sense of data.',
    nextSEO: {
        twitter: {
            handle: '@Blockworks_',
            site: '@Blockworks_',
            cardType: 'summary_large_image',
        },
    },
    ogImageUrl: `${process.env.SITE_URL}/images/og/BWR-OG-IMG-1200x630.png`,
    PROD_URL: process.env.VERCEL_PROJECT_PRODUCTION_URL!,
    researchURL: getResearchUrl(),
    ALGOLIA_KEY: process.env.ALGOLIA_KEY!,
    ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID!,
};

export { StudioConfig };
