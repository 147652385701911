import { StudioRoutePath } from '@studio/models/routes';
import NextAuth from 'next-auth';

import { makeAuthConfig, NextAuthInstance } from '@blockworks/session';

// For more information on each option (and a full list of options) go to
// https://next-auth.js.org/configuration/options
export const { auth, handlers }: NextAuthInstance = NextAuth(
    makeAuthConfig({
        pages: {
            signIn: StudioRoutePath.SignIn(),
            newUser: StudioRoutePath.Dashboards(),
            error: StudioRoutePath.SignIn(),
        },
    }),
);
