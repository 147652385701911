import '@studio/assets/styles/global.css';
import '@blockworks/ui/libs/nprogress/styles.css';
import '@blockworks/viz/dashboard-grid/styles.css';

import * as React from 'react';
import { UserProfileTheme } from '@prisma/client';
import { StudioConfig } from '@studio/config';
import { QueryProvider } from '@studio/context/query-context';
import { auth } from '@studio/services/auth';
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import type { Session } from 'next-auth';

import { isServerSide } from '@blockworks/platform/utils';
import { AuthProvider } from '@blockworks/session/context';
import { ContentProps } from '@blockworks/ui/components';
import { inter, maisonNeue } from '@blockworks/ui/fonts';
import { useNProgress } from '@blockworks/ui/libs/nprogress';
import { VisualizationWorkerProvider } from '@blockworks/viz';

import { GlobalLayout } from '../layouts/global/global-layout';

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: React.ReactElement, metaProps?: any) => React.ReactNode;
    layoutProps?: { content: ContentProps };
};

const defaultMetaProps = {
    title: 'Blockworks Studio',
    description: '',
    canonical_url: `${StudioConfig.PROD_URL}`,
};

// Global App's properties
export type BlockworksStudioAppProps = AppProps & {
    Component: NextPageWithLayout;
    theme: UserProfileTheme;
    session: Session;
};

const BlockworksStudioApp = (appProps: BlockworksStudioAppProps) => {
    useNProgress();

    const { Component, pageProps, session } = appProps;
    // Get meta props from the page component if available and merge with default
    const metaProps = {
        ...defaultMetaProps,
        ...pageProps.meta,
    };

    // Use the layout defined at the page level, if available
    const getLayout = Component?.getLayout
        ? (page: React.ReactElement) => Component.getLayout!(page, metaProps)
        : (page: React.ReactElement) => <GlobalLayout metaProps={metaProps}>{page}</GlobalLayout>;

    return (
        <div className={`${inter.variable} ${maisonNeue.variable} font-body`}>
            <VisualizationWorkerProvider>
                <QueryProvider pageProps={pageProps}>
                    <AuthProvider initialSession={session}>{getLayout(<Component {...pageProps} />)}</AuthProvider>
                </QueryProvider>
            </VisualizationWorkerProvider>
        </div>
    );
};

BlockworksStudioApp.getInitialProps = async (appContext: AppContext) => {
    const initialProps = await App.getInitialProps(appContext);
    let session: Session | null = null;

    if (isServerSide()) {
        session = await auth(appContext.ctx);
    }

    return { ...initialProps, session };
};

export default BlockworksStudioApp;
