import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const CodeSnippetIcon = forwardRef(
    ({ title, titleId, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M13.578 5.244a.833.833 0 0 1 1.178 0l4.167 4.167a.833.833 0 0 1 0 1.178l-4.167 4.167a.833.833 0 0 1-1.178-1.178L17.154 10l-3.578-3.577a.833.833 0 0 1 0-1.179Zm-7.155 0a.833.833 0 0 1 0 1.179L2.845 10l3.578 3.578a.833.833 0 1 1-1.179 1.178L1.077 10.59a.833.833 0 0 1 0-1.178l4.167-4.167a.833.833 0 0 1 1.179 0Zm5.425-3.557c.449.1.732.545.632.994l-3.333 15a.833.833 0 1 1-1.627-.361l3.333-15c.1-.45.545-.733.995-.633"
                clipRule="evenodd"
            />
        </svg>
    ),
);
CodeSnippetIcon.displayName = 'CodeSnippetIcon';
export default CodeSnippetIcon;
