import React, { PropsWithChildren, useState } from 'react';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { QueryParamProvider } from '@blockworks/platform/libs/use-query-params';

type QueryProviderProps = PropsWithChildren<{
    pageProps: any;
}>;

const QueryProvider = ({ children, pageProps }: QueryProviderProps) => {
    const [queryClient] = useState(() => new QueryClient({}));

    return (
        <QueryParamProvider>
            <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={pageProps.dehydratedState}>{children}</HydrationBoundary>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </QueryParamProvider>
    );
};

export { QueryProvider };
