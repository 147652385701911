import 'react-loading-skeleton/dist/skeleton.css';

import { UserDropdown } from '@studio/components/common/user-dropdown';
import { StudioRoutePath } from '@studio/models/routes';
import { useRouter } from 'next/router';

import { Bit } from '@blockworks/ui/bit';
import { Menu } from '@blockworks/ui/components';
import { CodeSnippetIcon, DashboardIcon, QueriesIcon } from '@blockworks/ui/icon';

const STUDIO_ROUTES = [
    {
        label: 'Dashboards',
        path: StudioRoutePath.Dashboards(),
        Icon: DashboardIcon,
        isActive: (asPath: string) =>
            asPath === StudioRoutePath.Dashboards() || asPath.startsWith('/dashboards') || asPath.startsWith('/?'),
    },
    {
        label: 'Queries',
        path: StudioRoutePath.Queries(),
        Icon: CodeSnippetIcon,
        isActive: (asPath: string) => asPath.startsWith(StudioRoutePath.Queries()),
    },
    {
        label: 'Visualizations',
        path: StudioRoutePath.Visualizations(),
        Icon: QueriesIcon,
        isActive: (asPath: string) => asPath.startsWith(StudioRoutePath.Visualizations()),
    },
];

const TopNav = () => {
    const { asPath } = useRouter();

    return (
        <Bit.div display="flex" w="full" justifyContent="between" alignItems="center">
            <Menu orientation="horizontal">
                <Menu.List>
                    {STUDIO_ROUTES.map(({ label, path, Icon, isActive }) => (
                        <Menu.Item key={label} active={isActive(asPath)} href={path} variant="link" icon={Icon}>
                            {label}
                        </Menu.Item>
                    ))}
                </Menu.List>
            </Menu>
            <UserDropdown />
        </Bit.div>
    );
};

export { TopNav };
