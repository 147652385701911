import 'react-loading-skeleton/dist/skeleton.css';

import { SkeletonTheme } from 'react-loading-skeleton';
import { StudioMeta, StudioMetaProps } from '@studio/components/common/meta';
import { StudioLogo } from '@studio/components/common/studio-logo';

import { Layout } from '@blockworks/ui/components';

import { TopNav } from './top-nav';

type GlobalLayoutProps = React.PropsWithChildren<{
    metaProps: StudioMetaProps;
}>;

const GlobalLayout = ({ children, metaProps }: GlobalLayoutProps) => {
    return (
        <SkeletonTheme baseColor="var(--color-surface-selected)" highlightColor="var(--color-surface-deselected)">
            <StudioMeta {...metaProps} />
            <Layout grid>
                <Layout.Brand logo={<StudioLogo />} />
                <Layout.Topbar>
                    <TopNav />
                </Layout.Topbar>
                <Layout.ContentWithoutSidebar>{children}</Layout.ContentWithoutSidebar>
            </Layout>
        </SkeletonTheme>
    );
};

export { GlobalLayout };
