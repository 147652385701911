import { Link, LinkProps } from '@blockworks/ui/components';

type StudioLinkProps = LinkProps;

const StudioLink = ({ children, ...rest }: StudioLinkProps) => {
    return (
        <Link size="xs" {...rest}>
            {children}
        </Link>
    );
};

export type { StudioLinkProps };
export { StudioLink };
