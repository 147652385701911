import { StudioRoutePath } from './routes';

type BreadCrumb = {
    title: string;
    href: string;
};
type DynamicBreadCrumn = (params: { title: string; id: string }) => BreadCrumb;

type BreadCrumbConfig = BreadCrumb | DynamicBreadCrumn;

const dashboardBreadcrumbs = {
    allDashboards: {
        title: 'Dashboards',
        href: StudioRoutePath.Dashboards(),
    },
    viewDashboard: ({ title, id }) => ({
        title,
        href: StudioRoutePath.ViewDashboard({ id }),
    }),
} satisfies Record<string, BreadCrumbConfig>;

const queryBreadcrumbs = {
    allQueries: { title: 'Queries', href: StudioRoutePath.Queries() },
    editQuery: ({ title, id }) => ({
        title,
        href: StudioRoutePath.EditQuery({ id }),
    }),
    queryVisualizations: ({ id, title }) => ({
        title,
        href: StudioRoutePath.QueryVisualizations({ id }),
    }),
} satisfies Record<string, BreadCrumbConfig>;

const visualizationBreadcrumbs = {
    allVisualizations: { title: 'Visualizations', href: StudioRoutePath.Visualizations() },
    editVisualization: ({ title, id }) => ({
        title,
        href: StudioRoutePath.EditVisualization({ id }),
    }),
} satisfies Record<string, BreadCrumbConfig>;

export type { BreadCrumb };
export { dashboardBreadcrumbs, queryBreadcrumbs, visualizationBreadcrumbs };
