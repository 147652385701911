import { makeStringTemplate } from '@blockworks/platform/utils';

const StudioRoutePath = {
    Dashboards: makeStringTemplate('/'),
    SignIn: makeStringTemplate('/sign-in'),

    Queries: makeStringTemplate('/queries'),
    EditQuery: makeStringTemplate('/queries/{id}/edit'),
    QueryVisualizations: makeStringTemplate('/queries/{id}/visualizations'),

    Visualizations: makeStringTemplate('/visualizations'),
    EditVisualization: makeStringTemplate('/visualizations/{id}/edit'),

    EditDashboard: makeStringTemplate('/dashboards/edit/{id}'),
    ViewDashboard: makeStringTemplate('/dashboards/{id}'),
};

export { StudioRoutePath };
