import * as React from 'react';
import { StudioConfig } from '@studio/config';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { MetaTag, OpenGraphMedia } from 'next-seo/lib/types';

type StudioMetaProps = {
    title?: string;
    description?: string;
    canonical_url?: string;
    images?: ReadonlyArray<OpenGraphMedia>;
};

const StudioMeta = ({
    title = StudioConfig.title,
    description = StudioConfig.description,
    canonical_url,
    images,
}: StudioMetaProps) => {
    const metaTags: MetaTag[] = [{ name: 'viewport', content: 'width=device-width,initial-scale=1' }];

    return (
        <>
            <Head>
                <meta charSet="UTF-8" key="charset" />
                <link rel="apple-touch-icon" href={`${StudioConfig.SITE_URL}/apple-touch-icon.png`} key="apple" />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${StudioConfig.SITE_URL}/favicon-32x32.png`}
                    key="icon32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${StudioConfig.SITE_URL}/favicon-16x16.png`}
                    key="icon16"
                />
                <link rel="icon" href={`${StudioConfig.SITE_URL}/favicon.ico`} key="favicon" />
                <link rel="mask-icon" href={`${StudioConfig.SITE_URL}/safari-pinned-tab.svg`} color="#fff" />
                <link rel="manifest" href={`${StudioConfig.SITE_URL}/site.webmanifest`} />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
                <title>{title}</title>
            </Head>
            <NextSeo
                title={title}
                defaultTitle={StudioConfig.title}
                description={description}
                canonical={canonical_url}
                openGraph={{
                    title: title,
                    description: description,
                    url: canonical_url ?? StudioConfig.SITE_URL,
                    locale: StudioConfig.locale,
                    site_name: StudioConfig.title,
                    images: images ?? [{ url: StudioConfig.ogImageUrl }],
                }}
                additionalMetaTags={metaTags}
                {...StudioConfig.nextSEO}
            />
        </>
    );
};

export type { StudioMetaProps };
export { StudioMeta };
