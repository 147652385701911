import React from 'react';
import { UserProfileTheme } from '@prisma/client';
import { StudioLink } from '@studio/components/common/link';
import { StudioConfig } from '@studio/config';

import * as SessionComponents from '@blockworks/session/components';
import { authMutations } from '@blockworks/session/mutations';
import { THEME_OPTIONS, useTheme } from '@blockworks/session/theme';
import { FlexBox, MultiToggle } from '@blockworks/ui/components';
import { LinkExternalIcon } from '@blockworks/ui/icon';

const UserDropdown = () => {
    const { theme } = useTheme();
    const updateTheme = authMutations.updateTheme.use();

    return (
        <SessionComponents.UserDropdown>
            <FlexBox justifyContent="between" alignItems="center" px={4} py={2}>
                <MultiToggle
                    size="sm"
                    value={theme}
                    disabled={updateTheme.isPending}
                    ariaLabel="Change Color Mode"
                    onValueChange={(selectedTheme: UserProfileTheme) => {
                        updateTheme.mutate({ type: 'updateTheme', payload: selectedTheme });
                    }}
                    values={THEME_OPTIONS}
                />
                <StudioLink href={StudioConfig.researchURL} leadingIcon={LinkExternalIcon}>
                    Research
                </StudioLink>
            </FlexBox>
        </SessionComponents.UserDropdown>
    );
};

export { UserDropdown };
